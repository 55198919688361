import React from "react"
import { Helmet } from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/site.scss'
import '../../styles/desktop.scss'

import ut from '../../images/urban-truant.jpg'

export class Head extends React.Component {

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Urban Truant Technology</title>
          <meta name="description" content="Urban Truant offers an easy-to-use website design service to meet a variety of needs. All websites developed by Urban Truant are designed to be managed and edited by you – no need to contact anyone to upload a new photo, write a blog article or change some text on your home page!" />
          <meta charset="UTF-8" />
          <meta name="keywords" content="Urban Truant Technology" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />

          <meta property="og:title" content="Urban Truant Technology" />
          <meta property="og:description" content="Urban Truant offers an easy-to-use website design service to meet a variety of needs. All websites developed by Urban Truant are designed to be managed and edited by you – no need to contact anyone to upload a new photo, write a blog article or change some text on your home page!" />
          <meta property="og:image" content={ut} />

        </Helmet>
      </>
    )
  }
}
